// background-color
@mixin background-color-1 {
    background: #072539;
}

@mixin background-color-2 {
    background: #113651;
}

@mixin background-color-3 {
    background: #041928;
}

@mixin background-color-4 {
    background: #0f3a59;
}

@mixin background-color-5 {
    background: #346588;
}

@mixin background-color-6 {
    background: #46677e;
}

@mixin background-color-7 {
    background: #19364b;
}

@mixin background-color-8 {
    background: #255b81;
}

@mixin background-color-9 {
    background: #0f2b3c;
}

@mixin background-color-10 {
    background: #eeda85;
}

@mixin background-color-11 {
    background: #0b2e47;
}

// text-color
@mixin text-color-highlight-yellow {
    color: #eeda85;
}

@mixin text-color-highlight-red {
    color: #fd818a;
}

@mixin text-color-positive {
    color: #90eaea;
}

@mixin text-color-negative {
    color: #fd818a;
}


// border-color
@mixin border-color-1 {
    border-color: #072539;
}

@mixin border-color-2 {
    border-color: #113651;
}

@mixin border-color-3 {
    border-color: #041928;
}

@mixin border-color-4 {
    border-color: #0f3a59;
}

@mixin border-color-5 {
    border-color: #346588;
}

@mixin border-color-6 {
    border-color: #46677e;
}

@mixin border-color-7 {
    border-color: black;
}

@mixin border-color-8 {
    border-color: #255b81;
}

@mixin border-color-9 {
    border-color: #112c3e;
}

@mixin border-color-10 {
    border-color: #eeda85;
}

@mixin border-color-11 {
    border-color: #0b2e47;
}


// button
@mixin button-style-1 {
    background: #df7474;
    color: #160201;
    border-radius: 3px;
    transition: all 0.3s;

    &:hover:enabled {
        background: #d47272;
        color: white;
    }
}

@mixin button-style-2 {
    background: #7b92a2;
    color: #011522;
    border-radius: 3px;
    transition: all 0.3s;

    &:hover:enabled {
        background: #a1b9c9;
        color: #011522;
    }
}

@mixin button-style-3 {
    @include background-color-6;
    color: #ddd;
    border-radius: 3px;
    font-weight: bold;
    transition: all 0.3s;

    &:hover:enabled {
        @include background-color-5;
        color: white;
    }
}

@mixin button-style-4 {
    background: #528f8c;
    color: #ddd;
    border-radius: 3px;
    font-weight: bold;
    transition: all 0.3s;

    &:hover:enabled {
        background: #529c99;
        color: #03302d;
    }
}

@mixin button-style-5 {
    background: slategray;
    color: #0f171f;
    border-radius: 3px;
    font-weight: bold;
    transition: all 0.3s;

    &:hover:enabled {
        background: lightslategray;
        color: white;
    }
}

@mixin button-style-6 {
    @include background-color-5;
    color: #0f171f;
    border-radius: 3px;
    font-weight: bold;
    transition: all 0.3s;

    &:hover:enabled {
        @include background-color-6;
        color: white;
    }
}

@mixin button-style-7 {
    background: mediumpurple;
    color: #0d0420;
    border-radius: 3px;
    font-weight: bold;
    transition: all 0.3s;

    &:hover:enabled {
        background: #7652bf;
        color: white;
    }
}