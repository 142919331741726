@import '../../styles/variables';

.support-request-editor {
    font-weight: bold;
    &--main {
        >textarea {
            width: 320px;
            margin-top: 10px;
            @include background-color-6;
            color: white;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            resize: none;
            cursor: text;
            padding: 10px 15px;
            border-radius: 3px;
        }
        >button {
            @include button-style-4;
            width: 200px;
            border-radius: 3px;
            height: 34px;
            font-size: 14px;
            font-weight: bold;
            margin-top: 10px;

            >svg {
                transform: scale(1.2);
                vertical-align: middle;
                margin-right: 5px;
            }
        }
    }

    &--sent-message {
        font-size: 14px;
        margin-top: 10px;
        text-align: left;
        line-height: 20px;
    }
}

.alert-management-popup {
    background: #012b2bdb;
    margin-left: 0px;
    box-shadow: 0px 0px 3px black;
    border-radius: 3px;
    overflow: hidden;

    &--trigger {
        position: relative;
        font-size: 25px;
        background: none;
        color: #92c9ca;
        opacity: 0.8;
        padding: 0px;
        height: 30px;

        &:hover {
            opacity: 1;
        }
    }

    &--section {
        position: relative;
        padding-bottom: 10px;

        &--title {
            font-size: 16px;
            font-weight: bold;
            line-height: 31px;
            border-bottom: 1px solid #86a3a4;
            text-indent: 15px;
            color: white;
        }

        &--main {
            position: relative;
            padding: 5px 15px;
        }
    }

    &--portfolio-table {
        table {
            position: relative;
            border-collapse: collapse;
            font-weight: bold;
            line-height: 24px;

            th {
                font-size: 14px;
                color: #acc5c5;
                text-align: left;
                &:not(:last-child) {
                    padding-right: 10px;
                }
            }

            td {
                font-size: 14px;
                &:not(:last-child) {
                    padding-right: 10px;
                }
            }
        }
    }

    &--is-fetching-details {
        float: right;
        padding-right: 15px;
        font-size: 14px;
        color: #acc5c5;
    }

    &--duration-input {
        color: white;
        cursor: text;
        width: 100%;
        font-size: 14px;
        background: #095252;
        font-weight: bold;
        text-indent: 6px;
        height: 24px;
        border: none;
        border-radius: 3px;
        width: 85px;
    }

    &--mute-button {
        height: 24px;
        border: none;
        font-size: 14px;
        font-weight: bold;
        border-radius: 2px;
        width: 85px;
        color: #651b1b;
        background: #d8b9b9;

        &:hover {
            background: #c9626a;
            color: white;
        }

        &:disabled {
            background: #ddd !important;
            color: lightslategray !important;
        }
    }

    &--notify-traders-button {
        width: 200px;
        border-radius: 3px;
        height: 34px;
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
        background: #e1b23b;
        color: #342602;

        &:hover {
            background: #e5bd59;
            color: white;
        }

        >svg {
            margin-right: 8px;
            transform: scale(1.4) translateY(1.5px);
        }
    }

    &--notify-traders-message {
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
    }

    &--state {
        display: flex;
        align-items: center;

        >span {
            width: 9px;
            height: 9px;
            background: #67b7b7;
            border-radius: 50px;
            margin-right: 6px;
            display: block;
            box-shadow: 0px 0px 10px black;

            &.unkown {
                background: #dfc278;
            }

            &.muted {
                background: #dd858c;
            }
        }
    }
}

.alert-management {
    overflow: hidden;
    &--section {
        position: relative;
        padding-bottom: 10px;

        &--title {
            font-size: 16px;
            font-weight: bold;
            line-height: 28px;
            border-bottom: 1px solid;
            @include border-color-2;
            color: white;
        }

        &--main {
            position: relative;
            padding: 5px 0px;

            &.notify-traders {
                >textarea {
                    width: 320px;
                    margin-top: 10px;
                    @include background-color-6;
                    color: white;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: bold;
                    resize: none;
                    cursor: text;
                    padding: 10px 15px;
                    border-radius: 3px;
                }
            }
        }
    }

    &--portfolio-table {

        table {
            position: relative;
            border-collapse: collapse;
            font-weight: bold;
            line-height: 24px;

            th {
                font-size: 13px;
                color: #bbb;
                opacity: 0.8;
                text-align: left;
                white-space: nowrap;
                padding: 3px 10px;
                @include background-color-1;

                border-bottom: 1px solid;
                @include border-color-3;
            }

            td {
                font-size: 14px;
                padding: 2px 10px;

                &:not(:last-child) {
                    padding-right: 10px;
                }
            }

            tbody {
                tr {
                    &:first-child {
                        td {
                            padding-top: 6px;
                        }
                    }
                }
            }
        }

        &--alert-type {
            background: #103048 !important;
            font-size: 14px !important;
            color: white !important;
        }
    }

    &--is-fetching-details {
        float: right;
        font-size: 14px;
        color: #ddd;
    }

    &--duration-input {
        color: white;
        cursor: text;
        width: 100%;
        font-size: 14px;
        @include background-color-6;
        font-weight: bold;
        text-indent: 6px;
        height: 24px;
        border: none;
        border-radius: 3px;
        width: 85px;
    }

    &--mute-button {
        @include button-style-1;
        height: 24px;
        border: none;
        font-size: 14px;
        font-weight: bold;
        border-radius: 2px;
        width: 85px;

        &:disabled {
            opacity: 0.4;
        }
    }

    &--notify-all-popup {
        @include background-color-2;
        border-radius: 3px;
        padding: 10px;
        box-shadow: 0px 0px 3px black;

        &--description {
            font-size: 14px;
            display: flex;
            align-items: center;
            line-height: 20px;
            font-weight: bold;
            gap: 10px;

            >svg {
                font-size: 20px;
            }
        }

        &--confirm-button {
            @include button-style-3;
            width: 100%;
            margin-top: 10px;
            height: 30px;
        }
    }

    &--notify-traders-button {
        width: 200px;
        border-radius: 3px;
        height: 34px;
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
        background: #e1b23b;
        color: #342602;
        margin-right: 10px;

        &:hover {
            background: #e5bd59;
            color: white;
        }

        >svg {
            margin-right: 8px;
            transform: scale(1.4) translateY(1.5px);
        }

        &.trader-on-duty {
            @include button-style-2;

            >svg {
                transform: scale(1.2) translateY(1.6px);
            }
        }

        &.it-support {
            @include button-style-4;

            >svg {
                transform: scale(1.6) translateY(1.6px);
            }
        }

        &.specific-member {
            @include button-style-5;

            >svg {
                transform: scale(1.3) translateY(1.6px);
            }
        }
    }

    &--users-popup {
        position: relative;
        display: inline-block;

        &--main {
            position: absolute;
            display: flex;
            flex-direction: column;
            z-index: 1;
            bottom: 40px;
            box-shadow: 0px 0px 3px black;
        }

        &--user {
            @include button-style-5;
            border-radius: 0px;
            font-size: 14px;
            padding: 5px 10px;

            &:not(:last-child) {
                border-bottom: 1px solid;
                @include border-color-2;
            }
        }
    }

    &--notify-traders-message {
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
    }

    &--state {
        display: flex;
        align-items: center;

        >span {
            width: 9px;
            height: 9px;
            background: #67b7b7;
            border-radius: 50px;
            margin-right: 6px;
            display: block;
            box-shadow: 0px 0px 10px black;

            &.unkown {
                background: #dfc278;
            }

            &.muted {
                background: #dd858c;
            }
        }
    }
}

.notify-it-button {
    @include button-style-2;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-radius: 2px;

    >svg {
        margin-right: 5px;
        font-size: 16px;
    }

    &--loading {
        width: 15px !important;
        height: 15px !important;
        margin-right: 5px;
    }

    &--popup {
        display: flex;
        flex-direction: column;
        @include background-color-1;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        border-radius: 0px;
        box-shadow: 0px 0px 3px black;

        &--caption {
            font-size: 13px;
        }

        &--confirm-button {
            position: relative;
            @include button-style-4;
            margin-top: 10px;
            width: 100%;
            height: 24px;
        }
    }
}