@import '../../styles/variables';

.pricing-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar{
        height: 0px;
    }

    &--search {
        height: 28px;
        min-height: 28px;

        &--input {
            position: relative;
            display: block;
            width: calc(100% - 20px);
            padding: 0px 10px;
            @include background-color-2;
            height: 28px;
            font-size: 14px;
            font-weight: bold;
            color: #eee;
            cursor: text;
            border-radius: 3px;
        }

        &--result {
            position: absolute;
            max-height: 300px;
            overflow: auto;
            @include background-color-8;
            z-index: 1;
            box-shadow: 0px 0px 3px black;
            margin-top: 5px;
            border-radius: 3px;
            z-index: 5;
        }

        &--result-item {
            position: relative;
            border-bottom: 1px solid;
            font-size: 14px;
            padding: 0px 10px;
            height: 30px;
            line-height: 30px;
            font-weight: bold;
            cursor: pointer;
            @include border-color-3;

            &:hover {
                @include background-color-6;
            }
        }
    }

    &--body {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 10px;
        min-width: 200px;
    }

    &--item {
        position: relative;
        padding: 10px;
        @include background-color-7;
        z-index: 2;
        cursor: move;

        &:not(:last-child) {
            border-bottom: 1px solid black;
        }

        &--symbol-name {
            font-size: 16px;
            font-weight: bold;
            color: #eee;
            line-height: 20px;
        }

        &--timestamp {
            font-size: 13px;
            line-height: 18px;
            font-weight: bold;
            color: #ddd;
            opacity: 0.8;
        }

        &--price-wrapper {
            position: relative;
            display: flex;
            margin-top: 5px;
        }

        &--last-price {
            font-weight: bold;
            color: #ddd;
            width: 33.333%;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            
            &--label {
                display: block;
                font-size: 13px;
                font-weight: bold;
                line-height: 14px;
                white-space: nowrap;
                opacity: 0.8;
            }
        }

        &--bid-ask-section {
            position: relative;
            width: 33.3333%;
            line-height: 17px;

            &.BID {
                color: #90eaea;
            }
            &.ASK {
                color: #fd818a;
            }
            &--label {
                font-size: 13px;
                line-height: 14px;
                font-weight: bold;
                opacity: 0.8;
            }
            &--pricing {
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
            }
        }

        &--remove-button {
            position: absolute;
            right: 10px;
            font-size: 20px;
            padding: 0;
            width: 18px;
            background: none;
            color: #ddd;
            top: 10px;
            display: none;
            cursor: pointer;
            z-index: 5;

            &:hover {
                color: white;
            }
        }

        &:hover {
            .pricing-container--item--remove-button {
                display: block;
            }
        }
    }
}

.symbol-popup {
    overflow: auto;
    height: auto;
    box-shadow: 0px 0px 3px black;
    @include background-color-1;

    &--title {
        position: sticky;
        z-index: 2;
        top: 0px;
        @include background-color-2;
        font-size: 13px;
        font-weight: bold;
        color: #a6bdbd;
        padding: 5px 9px;
        >span {
            color: white;
        }
    }

    &--trigger {
        cursor: pointer;
        &:hover {
            color: white;
        }
    }

    &--header {
        position: relative;
        padding: 0px 9px;
        font-weight: bold;
    }

    &--name {
        font-size: 14px;
        color: #96d0cf;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--pricing {
        &--timestamp {
            font-size: 12px;
            color: #ddd;
            margin-top: 1px;
        }

        &--body {
            position: relative;
            display: flex;
            margin-top: 5px;
        }

        &--section {
            position: relative;
            width: 33.3333%;

            &.bid {
                color: #8feaea;
            }

            &.ask {
                color: #fd8089;
            }

            &.last-price {
                color: white;
            }
        }

        &--name {
            font-size: 12px;
            font-weight: normal;
        }

        &--value {
            font-size: 13px;
        }
    }

    &--toggle-detail-button {
        position: absolute;
        right: 9px;
        top: -1px;
        background: none;
        border: 1px solid #ddd;
        font-size: 12px;
        color: #ddd;
        font-weight: bold;
        padding: 2px 5px;
        width: 77px;

        &:hover {
            background: #ddd;
            color: #0a393c;
        }
    }

    &--tabs {
        position: relative;
        display: flex;
        margin: 13px 9px 0px;
        border: 1px solid #82b7b9;
        border-radius: 2px;
    }

    &--tab-button {
        width: 50%;
        font-size: 12px;
        background: none;
        color: #ddd;
        font-weight: bold;
        height: 23px;
        padding: 0px;
        border-right: 1px solid #82b7b9;

        &:last-child {
            border-right: none;
        }

        &.active {
            background: #82b7b9;
            color: #042729;
        }
    }

    &--body {
        position: relative;
        overflow: auto;
        padding: 9px 0px;
    }

    &--account-balance, &--positions {
        &--header {
            position: relative;
            border-bottom: 1px solid;
            @include border-color-5;
            color: white;
        }
        &--title {
            font-size: 16px;
            font-weight: bold;
            line-height: 25px;
        }
        &--toggle-detail-button {
            position: absolute;
            right: 0px;
            bottom: 3px;
            font-size: 12px;
            font-weight: bold;
            background: transparent;
            border: 1px solid;
            min-width: 75px;
            white-space: nowrap;
            @include button-style-2;
        }
    }

    &--account-balance {
        position: relative;

        &--body {
            position: relative;
            max-height: 490px;
            overflow-y: auto;
        }

        .account-balance {
            &--name {
                display: none;
            }
    
            &--spot, &--margin, &--cross-margin, &--cross, &--future, &--swap, &--option {
                &--title {
                    display: none;
                }
                &--item {
                    position: relative;
                    padding: 10px 5px;
                    border-bottom: 1px solid;
                    @include border-color-4;
                }
            }

            .margin-account-balance-item--pair-table thead {
                background: #1a6667;
            }

            .spot-account-balance-item, .swap-account-balance-item, .cross-margin-account-balance-item, .spot-loan-account-balance-item, .spot-loan-account-balance-item, .option-account-balance-item,
            .bnbfuta-pm2-unified-account-balance-item, .cross-account-balance-item, .future-account-balance-item {

                &--header--info-bar--account-type {
                    height: 18px;
                    line-height: 19px;
                    font-size: 12px;
                }
                &--coin {
                    font-size: 14px;
                    line-height: 20px;
                }

                &--summary {
                    margin: 0;
                }
                
                &--inline-data {
                    &--name {
                        font-size: 12px;
                    }
                    &--value {
                        font-size: 13px;
                    }
                }
            }

            &--empty-message {
                margin-top: 7px;
            }
        }

        .account-asset-item {
            position: relative;
            padding: 10px 5px;
            border-bottom: 1px solid;
            @include border-color-4;

            &--account-name {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &--positions {
        &--notional-sum {
            padding: 5px;
            line-height: 16px;
            border-bottom: 1px dashed;
            @include border-color-5;

            &--name {
                float: left;
                font-size: 13px;
                font-weight: bold;
                color: #ddd;
            }
            &--value {
                float: right;
                font-size: 15px;
                font-weight: bold;
                >span {
                    &.positive {
                        color: #84fbf9;
                    }
                    &.negative {
                        color: #fd818a;
                    }
                }
            }
        }

        &--sub-title {
            font-size: 13px;
            font-weight: bold;
            margin-top: 5px;
            border-bottom: 1px dashed;
            @include border-color-5;
            color: #ddd;
        }

        &--portfolios {
            position: relative;
            margin-top: 5px;
            border-bottom: 1px solid;
            @include border-color-2;

            &--item {
                float: left;
                display: inline-flex;
                align-items: center;
                font-weight: bold;
                margin-right: 10px;
                margin-bottom: 5px;

                >label {
                    margin-right: 5px;
                    font-size: 14px;
                }

                .checkbox {
                    font-size: 14px;
                    width: 18px;
                    height: 18px;
                }
            }
        }

        &--list {
            position: relative;
            max-height: 370px;
            overflow-y: auto;
        }

        &--item {
            position: relative;

            .position-item {
                padding: 10px 5px;

                border-bottom: 1px solid;
                @include border-color-4;

                &--header {
                    &--info {
                        margin-bottom: 0px;
                        line-height: 20px;
                    }
                    &--timestamp {
                        margin-left: unset;
                    }
                    &--title {
                        margin-top: 0px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                &--pricing-wrapper {
                    margin: 0px;
                }
                &--direction-table, &--data, &--pricing-wrapper {
                    width: 100%;
                }
                &--direction-table {
                    th, td {
                        font-size: 13px;
                    }
                }
                &--pricing-section {
                    &--label {
                        font-size: 12px;
                        line-height: 16px;
                    }
                    &--value {
                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }
        }

        &--empty-message {
            text-align: center;
            font-size: 13px;
            font-weight: bold;
            margin-top: 7px;
        }
    }

    &--funding-rates {
        &--title {
            position: relative;
            border-bottom: 1px solid;
            @include border-color-5;
            color: white;
            font-size: 16px;
            font-weight: bold;
            line-height: 25px;

            &--last-update-time {
                font-size: 12px;
                float: right;
                color: white;
                opacity: 0.7;
            }
        }
        .symbol-funding-rates {
            padding-top: 5px;
            &--header {
                padding: 0px 9px;
                font-weight: bold;
            }
            &--quote-selectors {
                margin: 0px;
            }
            &--table {
                width: 100%;
                margin-top: 5px;
            }
            &--data {
                &--time {
                    font-size: 12px;
                }
                &--value {
                    font-size: 13px;
                }
            }
        }
    }

    &--row-1 {
        display: flex;
        padding: 0px 10px;
    }

    &--account-balance, &--positions, &--funding-rates {
        width: 260px;
        display: flex;
        flex-direction: column;
        min-height: 300px;
    }

    &--funding-rates {
        width: 330px;
    }

    &--positions, &--funding-rates {
        margin-left: 15px;
    }
}

.symbol-funding-rates {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--header {
        position: relative;
        line-height: 2px;
        padding: 9px 7px 0px;
    }

    &--title {
        font-size: 15px;
        font-weight: bold;
        float: left;
    }

    &--last-update-time {
        font-size: 12px;
        float: right;
    }

    &--quote-selectors {
        position: relative;
        display: flex;
        margin: 0px 9px;
        height: 24px;
        border: 1px solid;
        @include border-color-6;
        border-radius: 3px;

        &--item {
            width: 50%;
            height: 100%;
            font-weight: bold;
            font-size: 12px;
            background: none;
            color: #ddd;
            transition: all 0.3s;

            &:hover {
                color: white;
            }

            &:not(:last-child) {
                border-right: 1px solid;
                @include border-color-6;
            }
            &.selected {
                @include background-color-6;
                color: white;
            }
        }
    }

    &--table-wrapper {
        position: relative;
        height: 100%;
    }

    &--table {
        position: relative;
        width: 100%;
        font-size: 13px;
        font-weight: bold;

        &--header {
            @include background-color-1;
            margin: 0 !important;
            height: 24px;
            line-height: 25px;
            font-size: 13px;
            text-indent: 2px;

            &.sorted {
                @include text-color-highlight-yellow;
            }

            &.ReactVirtualized__Table__headerColumn {
                &:first-child {
                    text-indent: 5px;
                }
            }
        }

        &--row {
            border-bottom: 1px solid;
            @include border-color-2;

            &.ReactVirtualized__Table__headerRow {
                border: none;
            }
        }

        &--no-content {
            margin-top: 10px;
            text-align: center;
        }
    }

    &--header {
        position: relative;
    }

    &--data {
        &--time {
            font-size: 13px;
            opacity: 0.8;
        }

        &--value {
            font-size: 14px;
            &.positive {
                @include text-color-positive;
            }
            &.negative {
                @include text-color-negative;
            }
        }
    }
}

.order-book {
    position: relative;
    width: 100%;

    &--title {
        position: relative;
        border-bottom: 1px solid #99b3b3;
        color: #c4dcdc;
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
    }

    &--timestamp {
        font-size: 13px;
        float: right;
        opacity: 0.8;
    }

    &--body {
        position: relative;
        min-width: 150px;

        &--header {
            &--text {
                font-size: 13px;
                font-weight: bold;
                color: white;
                opacity: 0.5;
                &.price {
                    float: left;
                }
                &.amount {
                    float: right;
                }
            }
        }

        &--main {
            margin-top: 5px;
        }

        &--footer {
            position: relative;
            margin-top: 10px;
            line-height: 24px;
            &--timestamp {
                float: left;
                font-size: 13px;
                line-height: 24px;
                font-weight: bold;
                color: #ddd;
            }
            &--resubscribe-button {
                float: right;
                font-weight: bold;
                font-size: 14px;
                height: 24px;
                padding: 0px 10px;
                @include button-style-3;
            }
        }
    }

    &--block {
        &--item {
            position: relative;
            font-size: 14px;
            font-weight: bold;
            padding: 0px 5px;
            line-height: 26px;

            &--price {
                position: relative;
                float: left;
                cursor: pointer;

                &.buy {
                    color: #8fe6e8;
                }
                &.sell {
                    color: #fd818a;
                }
                &:hover {
                    transform: scale(1.1);
                }
            }
            &--amount {
                position: relative;
                float: right;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                }
            }
            &--cumulative-amount-bar {
                position: absolute;
                height: 100%;
                opacity: 0.2;
                left: 0;
                top: 0px;
                &.buy {
                    background: #8fe6e8;
                }
                &.sell {
                    background: #fd818a;
                }
            }

            &:nth-child(even) {
                .order-book--block--item--cumulative-amount-bar {
                    opacity: 0.1;
                }
            }
        }
    }
}

.symbol-funding-rate-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--search {
        position: relative;
        display: block;
        width: calc(100% - 20px);
        height: 28px;
        padding: 0px 10px;
        @include background-color-2;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }  

    &--menu {
        position: relative;
        height: 28px;
        line-height: 28px;
        display: flex;
        margin-top: 5px;

        &--tags {
            position: relative;
            z-index: 2;
            float: left;
        }
    }

    &--info {
        position: relative;
        width: 100%;
        white-space: nowrap;
    }

    &--last-update-time {
        font-size: 13px;
        font-weight: bold;
        color: #ddd;
    }

    &--toggle-unkown-symbol-button {
        position: relative;
        background: no-repeat;
        color: #ddd;
        font-size: 20px;
        padding: 0px;
        transform: translateY(3px);
        margin-left: 10px;

        &:hover {
            color: white;
        }

        &--popup {
            @include background-color-4;
            font-size: 13px;
            font-weight: bold;
            padding: 5px 10px;
            border-radius: 3px;
            box-shadow: 0px 0px 3px #000a0a;
        }
    }

    &--body {
        position: relative;
        margin-top: 5px;
        height: 100%;
    }
}

.cumulative-funding-rate-popup {
    background: #022323f2;
    width: 680px;
    box-shadow: 0px 0px 3px black;
    border-radius: 5px;
    overflow: hidden;

    &--header {
        line-height: 25px;
        background: #0f6060;

        &--title {
            font-size: 13px;
            font-weight: bold;
            margin-left: 11px;
        }
    }

    &--trigger-button {
        position: absolute;
        right: 0px;
        padding: 0px;
        width: 21px;
        height: 21px;
        margin: 4px;
        background: #267f80;
        color: #eee;
        font-size: 17px;
        border-radius: 2px;
        opacity: 0.8;
        margin-right: 6px;

        &:hover {
            opacity: 1.0;
        }

        >svg {
            transform: translate(1px, 3px);
        }
    }
}

.symbol-funding-rate-history {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 400px;
    min-width: 400px;

    &--header {
        position: relative;
        border-bottom: 1px solid;
        @include border-color-2;

        &--block {
            display: table-row;

            &--name, &--body {
                display: table-cell;
                white-space: nowrap;
                padding: 5px 0px;
            }
            &--name {
                font-size: 13px;
                font-weight: bold;
                line-height: 28px;
                vertical-align: top;
            }
            &--body {
                width: 100%;
                padding-left: 15px;
            }
        }
    }

    &--time-ranges {
        display: inline-flex;
    }

    &--time-range {
        font-size: 14px;
        font-weight: bold;
        width: 85px;
        height: 28px;
        background: transparent;
        border: 1px solid;
        @include border-color-5;
        color: #ddd;

        &:not(:last-child) {
            border-right: none;
        }

        &:hover {
            color: white;
        }

        &.active {
            @include background-color-6;
            color: white;
        }
    }

    &--symbols {
        &--item {
            display: flex;
            float: left;
            line-height: 26px;
            border: 1px solid;
            @include border-color-5;
            margin-right: 10px;
            margin-bottom: 10px;

            >span {
                font-size: 13px;
                padding: 0px 7px;
                font-weight: bold;
            }

            &--remove-button {
                height: 26px;
                width: 26px;
                @include background-color-6;
                color: #ddd;
                font-size: 15px;

                &:hover {
                    color: white;
                }

                >svg {
                    transform: translate(0px, 2px);
                }
            }

            &.has-nil-history {
                border-color: #e2cb6a;

                .symbol-funding-rate-history--symbols--item--remove-button {
                    background: #e2cb6a;
                    color: #483c0a;

                    &:hover {
                        color: white;
                    }
                }
            }
        }

        &--symbol-select, &--pair-select {
            float: left;
            width: auto;
            margin-right: 10px;
            margin-bottom: 10px;

            .search-select {
                &--current-option {
                    font-size: 13px;
                    font-weight: bold;
                    padding: 0px 10px;
                    color: #ddd;
                    border-radius: 3px;
                    height: 28px;
                    line-height: 29px;
                    transition: all 0.3s;
                    @include background-color-6;
                    @include border-color-4;

                    &:hover {
                        @include background-color-5;
                        color: white;
                    }
                }
                &--placeholder {
                    opacity: 1.0;
                }
                &--input-icon {
                    display: none;
                }
                &--empty-message {
                    white-space: nowrap;
                }
                &--options-wrapper {
                    position: fixed;
                }
            }
        }

        &--clear-button {
            float: left;
            width: auto;
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: bold;
            padding: 0px 10px;
            background: transparent;
            @include button-style-1;
            border-radius: 3px;
            line-height: 29px;
            height: 28px;

            &:hover {
                color: white;
            }
        }
    }

    &--body {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &--fetching-tag {
        position: absolute;
        right: 11px;
        font-size: 12px;
        font-weight: bold;
        color: #f7d98f;
        top: 6px;
    }

    &--chart-canvas-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &--tooltip {
        position: absolute;
        left: 60px;
        top: 12px;
        padding: 0px 2px;
        border-radius: 2px;

        &--row {
            display: table-row;

            &--marker-wrapper, &--name, &--value {
                display: table-cell;
            }

            &--marker {
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 10px;
                box-shadow: 0px 0px 2px #032525;
            }

            &--name {
                padding: 0px 7px;
                font-size: 13px;
                font-weight: bold;
                opacity: 0.8;
            }

            &--value {
                font-size: 13px;
                font-weight: bold;
            }
        }
    }
}

.defi-lending-info-container {
    position: relative;
    width: 100%;

    &--header {
        position: relative;
    }

    &--search-input {
        display: block;
        width: calc(100% - 20px);
        padding: 0px 10px;
        height: 28px;
        min-height: 28px;
        @include background-color-2;
        font-size: 14px;
        border-radius: 3px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }

    &--body {
        position: relative;
        width: 100%;
    }
}

.defi-lending-item {
    position: relative;
    border-bottom: 1px solid;
    padding: 10px 0px;
    @include border-color-9;

    &--header {
        line-height: 24px;
        &--symbol {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
        }

        &--platform {
            float: right;
            font-size: 13px;
            height: 22px;
            border: 1px solid;
            padding: 0px 8px;
            border-radius: 3px;
            font-weight: bold;
            &.COMPOUND {
                color: #05d395;
            }
            &.DYDX {
                color: #ddd;
            }
        }
    }

    &--rows {
        display: table;
        width: 100%;
        margin-top: 5px;
    }

    &--row {
        display: table-row;
        color: #c7d8d8;
        &--name, &--value {
            display: table-cell;
            white-space: nowrap;
            font-size: 14px;
            line-height: 20px;
        }
        &--name {
            opacity: 0.8;
        }
        &--value {
            width: 100%;
            font-weight: bold;
            text-align: right;
        }
    }
    table {
        width: 100%;
        white-space: nowrap;
        margin-top: 8px;
        border-collapse: collapse;
        th {
            font-size: 13px;
            padding: 4px;
            color: #eee;
            @include background-color-7;   
            &:first-child {
                @include background-color-1;  
            }
        }

        td {
            font-size: 14px;
            text-align: center;
            font-weight: bold;
            padding: 2px 0px;
        }
    }
}

.symbol-multiple-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    @include background-color-3;
    padding: 0px 0px;
    box-shadow: 0px 0px 3px black;
    font-weight: bold;
    overflow: hidden;
    max-width: 900px !important;
    z-index: 101 !important;

    &--trigger {
        position: relative;
        height: 25px;
        line-height: 23px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: bold;
        background: transparent;
        border: 1px solid #488686;
        border-radius: 3px;
        color: white;

        &:hover {
            background: #488686;
        }
    }

    &--header {
        @include background-color-4;
        padding: 4px 9px;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        min-height: 20px;
    }

    &--main {
        position: relative;
        padding: 11px 9px;
        height: 100%;
        overflow: auto;
    }

    &--config-row {
        padding: 8px 9px;
        border-bottom: 1px solid;
        @include border-color-4;
    }

    &--toggle-all-button {
        margin-top: 2px;
        padding: 0px 11px;
        height: 32px;
        font-size: 14px;
        line-height: 30px;
        font-weight: bold;
        border-radius: 3px;
        background: transparent;

        @include button-style-2;
    }

    &--search-string {
        position: relative;
        height: 32px;
        margin-left: 10px;
        width: 300px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: bold;
        cursor: text;
        border-radius: 3px;
        @include background-color-2;
        color: white;
    }

    &--empty-result {
        position: relative;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
    }

    &--portfolios {
        position: relative;
        padding: 5px 20px 10px;
        overflow: auto;
    }

    &--item {
        position: relative;
        display: inline-flex;
        line-height: 24px;
        margin-right: 21px;
        font-size: 14px;

        >span {
            &.not-exist-warning {
                text-decoration: line-through;
                color: #fd818a;
            }
        }

        .checkbox {
            width: 20px;
            height: 20px;
            transform: translateY(2px);
            margin-left: 7px;

            >svg {
                position: absolute;
            }
        }
    }

    &--portfolio {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &--header {
            border-bottom: 1px solid #517070;
            padding: 5px 0px;
            line-height: 20px;
            font-size: 14px;

            >label {
                font-size: 16px;
                color: white;
            }

            >button {
                padding: 0px 7px;
                height: 24px;
                font-size: 14px;
                font-weight: bold;
                border-radius: 3px;
                border: 1px solid #7eafaf;
                background: transparent;
                color: #7eafaf;
                margin-left: 10px;

                &:hover {
                    background: #094242;
                }
            }
        }

        &--main {
            position: relative;
            margin-top: 5px;
        }

        &--item {
            position: relative;
            display: inline-flex;
            line-height: 24px;
            margin-right: 21px;
            font-size: 14px;

            >span {
                &.not-exist-warning {
                    text-decoration: line-through;
                    color: #fd818a;
                }
            }

            .checkbox {
                width: 20px;
                height: 20px;
                transform: translateY(2px);
                margin-left: 7px;

                >svg {
                    position: absolute;
                }
            }
        }
    }
}

.high-low-prices {
    position: relative;
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: bold;
    height: 100%;

    &--header {
        &--row-1 {
            @include background-color-2;

            display: flex;
            align-items: center;
            height: 35px;
            min-height: 35px;
            padding: 0px 10px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        &--row-2 {
            @include background-color-9;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            height: 35px;
            min-height: 35px;
            white-space: nowrap;
        }
    }

    &--filters {
        display: flex;
        align-items: center;

        &--item {
            display: flex;
            align-items: center;

            margin-right: 20px;

            >label {
                font-size: 14px;
                opacity: 0.7;
                margin-right: 10px;
            }

            .search-select {
                font-size: 14px;

                &--current-option {
                    padding-right: 20px;
                }

                &--input-icon {
                    opacity: 0.7;
                    right: 0px;
                }
            }
        }
    }

    &--instruments-popup {
        font-size: 14px;
        &--trigger {
            font-size: 14px;
            text-decoration: underline;
            cursor: pointer;
            white-space: nowrap;
        }
        &--main {
            position: relative;
            display: flex;
            flex-direction: column;
            @include background-color-2;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;
            padding: 0px;
        }
        &--item {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            &:not(:last-child) {
                border-bottom: 1px solid;
                @include border-color-3;
            }

            >label {
                font-size: 14px;
                font-weight: bold;
                margin-left: 10px;
            }
        }
    }

    &--fetch-data-button {
        @include button-style-2;
        height: 100%;
        font-size: 14px;
        font-weight: bold;
        padding: 0px 10px;
        border-radius: 0px;
        white-space: nowrap;

        &:not(:last-child) {
            margin-right: 1px;
        }
    }

    &--time-ranges {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;

        >label {
            font: 14px;
            opacity: 0.7;
            margin-right: 8px;
        }

        &--list {
            height: 25px;
        }

        &--item {
            @include button-style-2;
            border-radius: 0px;
            height: 100%;
            width: 30px;
            font-size: 13px;
            font-weight: bold;

            &:not(:last-child) {
                margin-right: 1px;
            }

            &.selected {
                @include background-color-5;
                color: white;
            }
        }
    }

    &--should-hide-symbols-without-data{
        display: flex;
        align-items: center;
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid;
        @include border-color-6;
        height: 100%;

        >label {
            font-size: 14px;
            opacity: 0.7;
            margin-left: 5px;
        }
    }

    &--search-input {
        height: 100%;
        width: 100%;
        padding: 0px 10px;
        margin-left: 10px;
        background: transparent;
        font-size: 14px;
        font-weight: bold;
        color: white;
        cursor: text;
        border-left: 1px solid;
        @include border-color-6;
    }

    &--body {
        position: relative;
        height: 100%;
        margin-top: 10px;
    }

    &--table {
        &--no-content {
            text-align: center;
            margin-top: 10px;
        }

        .ReactVirtualized__Table__headerRow {
            @include background-color-1;
        }

        &--header {
            font-size: 13px;
            text-transform: initial;
            display: flex;
            margin-right: 0px !important;
            color: white;
            cursor: default !important;

            >span {
                text-align: left;
            }

            &.sortable {
                cursor: pointer !important;
            }

            &.sorted {
                @include text-color-highlight-yellow;
            }
        }

        &--row {
            color: #ddd;
            font-weight: bold;
            font-size: 14px;
            &.odd-row {
                @include background-color-9;
            }
        }
    }
}

.aggregated-option-positions {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .positive {
        @include text-color-positive;
    }

    .negative {
        @include text-color-negative;
    }

    &--header {
        &--row-1 {
            position: relative;
            @include background-color-2;
            display: flex;
            align-items: center;
        }
    }

    &--underlyings {
        position: relative;
        white-space: nowrap;
        width: 100%;
    }

    &--underlying {
        height: 30px;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        padding: 0px 20px;
        @include button-style-5;
        border-radius: 0px;
        opacity: 0.7;
        border-right: 1px solid;
        @include border-color-2;

        &.selected {
            opacity: 1.0;
        }

        &.selected {
            background: lightslategray;
            color: white;
        }
    }

    &--portfolios {
        white-space: nowrap;
        padding-right: 10px;
        font-size: 14px;
        font-weight: bold;

        >label {
            opacity: 0.7;
        }
    }

    &--expiry-dates, &--exchanges {
        padding-top: 10px;
        border-bottom: 1px solid;
        @include border-color-2;
        display: flex;
        align-items: flex-start;
        gap: 10px;

        >label {
            white-space: nowrap;
            font-size: 14px;
            font-weight: bold;
            opacity: 0.7;
        }
    }

    &--expiry-date, &--exchange {
        font-size: 14px;
        font-weight: bold;
        padding: 0px 10px;
        height: 20px;
        @include button-style-2;
        border-radius: 0px;
        opacity: 0.6;
        margin-bottom: 10px;

        &:not(:last-child) {
            margin-right: 10px;
        }

        &.selected {
            background: #a1b9c9;
            color: #011522;
            opacity: 1.0;
        }
    }

    &--exchanges {
        &--select-all-button {
            font-size: 14px;
            font-weight: bold;
            padding: 0px 10px;
            height: 20px;
            @include button-style-4;
            border-radius: 0px;
            margin-bottom: 10px;
        }
    }

    &--main {
        position: relative;
        height: 100%;
        overflow: auto;

        >table {
            position: relative;
            width: 100%;
            border-collapse: collapse;

            >thead {
                tr {
                    &:first-child {
                        @include background-color-1;
                        th {
                            font-size: 16px;
                            line-height: 30px;
                            padding: 0px;
                        }
                    }

                    &:last-child {
                        @include background-color-4;
                        font-weight: bold;
                        font-size: 14px;
                        padding: 0px 10px;
                        line-height: 20px;

                        td {
                            text-align: center;
                            color: #bbb;
                        }
                    }
                }
            }

            >tbody {
                >tr {
                    border-bottom: 1px dashed;
                    @include border-color-7;

                    td, th {
                        padding: 5px 10px;
                        font-weight: bold;
                    }

                    td {
                        &.highlight {
                            @include background-color-1;
                        }
                    }
                }
            }

            .left-align {
                text-align: left !important;
            }

            .right-align {
                text-align: right !important;
            }
        }
    }

    &--mark-iv {
        display: flex;

        &--item {
            &:not(:first-child) {
                margin-left: 20px;
            }

            >label {
                font-size: 12px;
                line-height: 16px;
                font-weight: bold;
                color: #ddd;
                opacity: 0.6;
            }

            &--value {
                font-size: 14px;
                line-height: 20px;
                font-weight: bold;
            }
        }
    }

    &--position-sum {
        &--popup {
            @include background-color-4;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;
        }

        &--value {
            position: relative;
            display: inline-block;
            transition: all 0.3s;
            &:hover {
                transform: scale(1.15);
            }

        }

        &--item {
            font-weight: bold;
            padding: 10px;
            >label {
                font-size: 16px;
            }

            &:not(:first-child) {
                border-top: 1px solid;
                @include border-color-3;
            }
        }

        &--data {
            margin-top: 5px;
            color: #ddd;

            .positive {
                @include text-color-positive;
            }
        
            .negative {
                @include text-color-negative;
            }

            &--row {
                font-size: 14px;
                line-height: 20px;
                display: flex;
                white-space: nowrap;
                >label {
                    position: relative;
                    width: 100%;
                    opacity: 0.6;
                }
            }
        }
    }
}

.symbol-management {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-weight: bold;

    &--header {
        display: flex;
        align-items: center;
        height: 35px;
        @include background-color-2;
        border-radius: 3px;
        white-space: nowrap;

        &--left {
            display: flex;
            align-items: center;
            height: 100%;

            >input {
                position: relative;
                height: 100%;
                padding: 0px 15px;
                margin: 0;
                width: 240px;
                font-size: 14px;
                font-weight: bold;
                color: white;
                background: transparent;
                border-right: 2px solid;
                @include border-color-3;
                cursor: text;
            }
        }

        &--right {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-left: auto;
            margin-right: 15px;
        }

        &--loading {
            width: 21px !important;
            height: 21px !important;
            margin-left: 15px;
        }

        &--refresh-button {
            @include button-style-2;
            display: flex;
            align-items: center;
            font-size: 14px;
            height: 25px;
            font-weight: bold;

            &--loading {
                width: 15px !important;
                height: 15px !important;
                margin-right: 5px;
            }

            >svg {
                margin-right: 5px;
            } 
        }
    }

    &--criteria-toggle {
        display: flex;
        align-items: center;
        margin-left: 15px;
        font-size: 14px;
        cursor: pointer;

        .checkbox {
            cursor: pointer !important;
            width: 21px;
            height: 21px;
        }

        >label {
            margin-left: 10px;
            cursor: inherit;
        }
    }

    &--table {
        .ReactVirtualized__Table__headerRow {
            @include background-color-1;
        }
        .ReactVirtualized__Table__Grid  {
            .ReactVirtualized__Table__rowColumn {
                white-space: pre-line;
                word-wrap: break-word;
            }
            .symbol-management--table  {
                &--row {
                    color: #ddd;

                    &.odd-row {
                        @include background-color-9;
                    }

                    .toggle {
                        height: 24px;

                        &.is-changed {
                            .toggle--track {
                                background: #dfc763;
                            }
                        }
        
                        &--button {
                            height: 20px;
                            width: 20px;
                        }
        
                        &--track {
                            width: 42px;
                        }
        
                        &--label {
                            line-height: 24px;
                        }
                    }

                    .positive {
                        @include text-color-positive;
                    }

                    .negative {
                        @include text-color-negative;
                    }
                }
            }
        }

        &--no-content {
            text-align: center;
            margin-top: 20px;
            color: #bbb;
        }

        &--wrapper {
            &.main {
                flex-grow: 1;
                margin-top: 10px;
            }
        }

        &--header {
            font-size: 14px;
            font-weight: bold;
            height: 27px;
            line-height: 27px;
        }
        
        &--row {
            font-size: 14px;
        }

        &--input-column {
            input {
                background: #34658838;
                height: 30px;
                font-size: 16px;
                padding: 0px 10px;
                margin: 0;
                color: white;
                cursor: text;
                font-weight: bold;

                &.is-changed {
                    color: #041928;
                    background: #dfc763;
                }
            }
        }

        &--actions {
            display: flex;
            align-items: center;
            gap: 10px;

            >button {
                font-weight: bold;
                font-size: 15px;
            }
        }

        &--enable-button, &--save-button, &--sync-button {
            display: flex;
            align-items: center;
            &--loading {
                width: 15px !important;
                height: 15px !important;
                margin-right: 8px;
            }
        }

        &--enable-button {
            @include button-style-3;
        }

        &--sync-button {
            @include button-style-5;
        }

        &--save-button {
            @include button-style-4;

            >svg {
                margin-right: 4px;
            }
        }

        &--reset-button {
            @include button-style-2;
        }

        &--leverage-management {
            width: 850px;
            @include background-color-2;
            height: 50%;
            overflow: hidden;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;

            &--trigger {
                @include button-style-7;
                white-space: nowrap;
            }

            .leverage-management {
                &--header {
                    padding: 10px;
                }

                &--criteria--accounts--trigger {
                    &:hover {
                        @include background-color-5;
                    }
                }

                .ReactVirtualized__Table__headerRow {
                    @include background-color-3;
                }

                &--table {
                    font-size: 14px;
                    &--wrapper {
                        margin-top: 0px;
                    }
                    &--row {
                        &.odd-row {
                            background: #18435c !important;
                        }
                    }
                }
            }
        }

        &--update-message {
            overflow: hidden;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @include text-color-highlight-yellow;
        }

        &--save-popup {
            @include background-color-2;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;
            padding: 10px;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: bold;
            max-width: 250px !important;
            word-break: break-word;

            &--confirm-button {
                @include button-style-3;
                margin-top: 10px;
                height: 30px;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

    &--add-symbol {
        &--trigger {
            @include button-style-3;
            display: flex;
            align-items: center;
            font-size: 14px;
            height: 25px;

            >svg {
                margin-right: 5px;
            } 
        }

        &--popup {
            @include background-color-4;
            padding: 10px;
            margin: 5px 0px;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;

            table {
                border-collapse: collapse;

                th {
                    text-align: left;
                    font-size: 14px;
                    padding-right: 10px;
                }

                td {
                    vertical-align: middle;
                    padding-right: 10px !important;

                    >button {
                        font-size: 18px;
                        height: 30px;
                        padding: none;
                        border: none;
                        background: none;
                        color: white;
                        opacity: 0.6;
                        display: flex;
                        align-items: center;
                        transition: all 0.3s;

                        &:hover {
                            opacity: 1.0;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding: 5px 0px;
                        }
                        &:not(:first-child) {
                            td {
                                border-top: 1px solid;
                                @include border-color-1;
                            }
                        }
                    }
                }
            }
        }

        &--input-wrapper {
            input {
                cursor: text;
                @include background-color-3;
                font-size: 14px;
                color: white;
                font-weight: bold;
                width: 230px;
                height: 32px;
                padding: 0px 10px;
            }
            &--warning-message {
                @include text-color-highlight-yellow;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                margin-top: 5px;
            }
        }

        &--status-message {
            max-width: 200px;
            display: block;
            font-size: 13px;
            font-weight: bold;
            color: #ddd;
        }

        &--footer {
            display: flex;
            margin-top: 10px;
            gap: 10px;
            align-items: center;
        }

        &--add-button {
            @include button-style-3;
            font-size: 14px;
            font-weight: bold;
            height: 32px;
            width: 250px;
            min-width: 250px;
            display: flex;
            align-items: center;
            justify-content: center;

            &--loading {
                width: 16px !important;
                height: 16px !important;
                margin-right: 8px;
            }

            &:disabled {
                opacity: 0.6;
            }
        }

        &--reset-button {
            @include button-style-2;
        }

        &--discard-button {
            @include button-style-1;
        }

        &--reset-button, &--discard-button {
            font-size: 14px;
            font-weight: bold;
            margin-left: auto;
            min-width: 65px;
            height: 32px;
        }
    }

    &--update-leverage {
        &--trigger {
            @include button-style-2;
            background: #7789bc;
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 14px;
            height: 25px;

            >svg {
                margin-right: 5px;
            } 
        }

        &--popup {
            @include background-color-4;
            padding: 10px;
            margin: 5px 0px;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;
        }
    }

    &--sync-option-symbols {
        &--trigger {
            @include button-style-4;
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 14px;
            height: 25px;

            >svg {
                margin-right: 5px;
            } 
        }

        &--popup {
            @include background-color-4;
            padding: 10px;
            margin: 5px 0px;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;
        }

        &--inputs {
            >div {
                display: flex;
                align-items: center;

                >label {
                    font-size: 13px;
                    font-weight: bold;
                }
                >input {
                    cursor: text;
                    @include background-color-3;
                    font-size: 14px;
                    color: white;
                    font-weight: bold;
                    height: 32px;
                    padding: 0px 10px;
                    margin-left: 10px;
                }
            }
        }

        &--status-message {
            max-width: 200px;
            display: block;
            font-size: 13px;
            font-weight: bold;
            color: #ddd;
        }

        &--footer {
            display: flex;
            margin-top: 10px;
            gap: 10px;
            align-items: center;
        }

        &--sync-button {
            @include button-style-3;
            font-size: 14px;
            font-weight: bold;
            height: 32px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &--loading {
                width: 16px !important;
                height: 16px !important;
                margin-right: 8px;
            }

            &:disabled {
                opacity: 0.6;
            }
        }

        &--discard-button {
            @include button-style-1;
            font-size: 14px;
            font-weight: bold;
            margin-left: auto;
            min-width: 65px;
            height: 32px;
        }

        &--message {
            @include background-color-9;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            margin-top: 10px;
            padding: 5px 10px;
            max-width: 200px;
            border-radius: 3px;
        }
    }

    &--added-symbols {
        height: 40%;
        display: flex;
        flex-direction: column;

        &--header {
            display: flex;
            align-items: center;
            @include background-color-2;
            height: 35px;
            padding: 0px 15px;
            border-radius: 3px;
            white-space: nowrap;

            >button {
                margin-left: auto;
                background: none;
                color: white;
                opacity: 0.6;
                transition: all 0.3s;
                background: none;
                font-size: 18px;
                display: flex;
                flex-direction: column;
                padding: 0;

                &:hover {
                    opacity: 1.0;
                }
            }
        }

        &--title {
            font-size: 16px;
        }

        &--main {
            flex-grow: 1;
            margin-top: 10px;
        }

        >hr {
            @include border-color-6;
            margin: 10px 0px;
        }
    }
}

.symbol-leverage-update-form {
    &--inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        >div {
            display: flex;
            align-items: center;

            >label {
                font-size: 13px;
                font-weight: bold;
                min-width: 60px;
            }

            >div, >input {
                width: 220px;
                margin-left: 10px;
                height: 32px;
                @include background-color-1;
                padding: 0px 10px;
                font-size: 14px;
                font-weight: bold;
                color: white;
            }

            >input {
                cursor: text;
            }

            .search-select {
                &--current-option {
                    line-height: 32px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &--accounts-popup {
        padding: 10px;
        box-shadow: 0px 0px 5px black;
        @include background-color-2;
        border-radius: 3px;
        min-width: 420px;
        overflow: auto;

        &--trigger {
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s;
            display: flex;
            white-space: nowrap;
        
            &:hover {
                @include background-color-3;
            }

            >div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            >span {
                margin-left: 5px;
            }
        }
    }

    &--footer {
        margin-top: 20px;

        &--messages {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        &--message {
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            padding: 4px 6px;

            &.pending {
                @include text-color-highlight-yellow;
                background: #eeda852e;
            }

            &.success {
                @include text-color-positive;
                background: #90eaea26;
            }

            &.failed {
                @include text-color-negative;
                background: #fd818a26;
            }
        }

        >button {
            margin-top: 10px;
            @include button-style-4;
            width: 100%;
            height: 32px;
            font-size: 16px;

            &:disabled {
                opacity: 0.7;
            }
        }
    }
}

.leverage-management {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-weight: bold;

    &--header {
        display: flex;
        align-items: center;

        &--buttons {
            margin-left: 20px;

            >button {
                @include button-style-4;
                height: 30px;
                font-weight: bold;
                font-size: 14px;
                padding: 0px 10px;
                white-space: nowrap;
            }
        }
    }

    &--criteria {
        display: flex;
        align-items: center;
        gap: 20px;

        >div {
            display: flex;
            align-items: center;
            gap: 10px;

            >label {
                font-size: 14px;
                font-weight: bold;
                opacity: 0.7;
            }

            >div {
                .search-select {

                    &--current-option {
                        width: 200px;
                        font-size: 14px;
                        padding: 0px 10px;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @include background-color-6;
                        transition: all 0.3s;

                        &:hover {
                            @include background-color-4;
                        }

                        &.not-clickable {
                            width: auto;
                            padding: 0;
                            background: none;
                        }
                    }
                }
            }
        }

        &--accounts {

            &--trigger {
                font-size: 14px;
                padding: 0px 10px;
                overflow: hidden;
                display: flex;
                align-items: center;
                height: 30px;
                cursor: pointer;
                @include background-color-6;
                transition: all 0.3s;

                >div {
                    max-width: 300px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                >span {
                    margin-left: 5px;
                }

                &:hover {
                    @include background-color-4;
                }
            }

            &--popup{
                @include background-color-2;
                padding: 10px;
                border-radius: 3px;
                box-shadow: 0px 0px 3px black;
                min-width: 450px;
                overflow-y: auto;
            }
        }
    }

    &--table--wrapper {
        position: relative;
        flex-grow: 1;
        margin-top: 10px;
    }

    &--table {
        .ReactVirtualized__Table__headerRow {
            @include background-color-1;
        }
        .ReactVirtualized__Table__Grid  {
            .ReactVirtualized__Table__rowColumn {
                white-space: pre-line;
                word-wrap: break-word;
            }
            .leverage-management--table  {
                &--row {
                    color: #ddd;
                    &.odd-row {
                        @include background-color-9;
                    }
                }
            }
        }

        &--header {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: bold;
            color: #ddd;
            gap: 10px;
            white-space: nowrap;
        }

        &--no-content {
            text-align: center;
            margin-top: 20px;
            color: #bbb;
        }
    }

    &--batch-leverage-update, &--batch-bybit-risk-id-update {
        
        &--trigger {
            @include button-style-2;
            font-size: 13px;
            white-space: nowrap;
            font-weight: bold;
        }

        &--popup {
            @include background-color-2;
            padding: 10px;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;
        }

        &--inputs {
            display: flex;
            flex-direction: column;
            gap: 10px;

            >div {
                display: flex;
                align-items: center;
                font-weight: bold;

                >label {
                    font-size: 14px;
                    min-width: 80px;
                }

                >input, >textarea {
                    @include background-color-6;
                    width: 200px;
                    resize: none;
                    font-size: 14px;
                    color: white;
                    line-height: 28px;
                    padding: 0px 10px;
                    cursor: text;
                    font-weight: bold;
                }
            }
        }

        >button {
            @include button-style-2;
            width: 100%;
            margin-top: 20px;
            height: 30px;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.price-alert-monitor {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--header {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &--main {
            display: flex;
            gap:  10px;
            align-items: center;
        }

        &--tags {
            display: flex;
            align-items: center;
            gap: 10px;

            >button {
                @include button-style-5;
                font-size: 14px;
                height: 28px;
                padding: 0px 10px;
                font-weight: bold;
                opacity: 0.7;

                &.selected {
                    opacity: 1.0 !important;
                    color: white !important;
                }
            }
        }
    }

    &--search-input {
        flex-grow: 1;
        height: 32px;
        padding: 0;
        margin: 0;
        @include background-color-2;
        font-size: 14px;
        font-weight: bold;
        color: #eee;
        padding: 0px 10px;
        cursor: text;
    }

    &--fetch-button {
        @include button-style-3;
        height: 32px;
        font-size: 14px;
        padding: 0px 10px;
        min-width: 92px;
        white-space: nowrap;
    }

    &--form-popup, &--edit-popup {
        position: relative;
        background: #183a53;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;

        &--trigger {
            @include button-style-2;
            height: 32px;
            width: 32px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
        }

        &--header {
            @include background-color-1;
            font-size: 13px;
            line-height: 24px;
            font-weight: bold;
            padding: 0px 10px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        &--main {
            padding: 10px;
            width: 320px;
        }
    }

    &--form {
        font-weight: bold;
        font-size: 16px;
    
        &--inputs {
            display: flex;
            flex-direction: column;
            gap: 10px;

            >div {
                display: flex;
                align-items: center;
                
                >label {
                    font-size: 13px;
                    min-width: 90px;
                }

                >input {
                    height: 28px;
                    @include background-color-5;
                    width: 100%;
                    padding: 0px 10px;
                    color: white;
                    cursor: text;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }

        &--symbol {
            height: 28px;
            @include background-color-5;
            width: 100%;

            .search-select {
                position: relative;
                height: 100%;

                &--current-option {
                    height: inherit;
                    line-height: 28px;
                    padding: 0px 10px;
                    font-size: 14px;
                }
            }
        }

        &--alert-types {
            display: flex;
            gap: 1px;
            flex-grow: 1;
            
            >button {
                @include button-style-5;
                border-radius: 0px;
                height: 28px;
                padding: 0px 10px;
                opacity: 0.7;
                flex-grow: 1;

                &.selected {
                    opacity: 1.0 !important;
                    color: white !important;
                }
            }
        }

        &--footer {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            >button {
                @include button-style-2;
                font-size: 14px;
                font-weight: bold;
                height: 32px;
            }
        }

        &--message {
            text-align: center;
            font-size: 13px;
            font-weight: bold;
            @include background-color-3;
            padding: 6px 10px;
            border-radius: 3px;
        }
    }

    &--list {
        margin-top: 10px;
        flex-grow: 1;
        overflow: auto;
        gap: 1px;
        display: flex;
        flex-direction: column;
    }

    &--item {
        position: relative;
        font-weight: bold;
        white-space: nowrap;
        @include background-color-1;
        padding: 10px;

        &--row-1 {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        &--symbol {
            position: relative;
            font-size: 18px;
        }
        &--alert-type {
            font-size: 13px;
            line-height: 20px;
            padding: 0px 8px;
            background: #f5b94c17;
            color: #f5b94c;
            border-radius: 3px;

            &.PREM_INDEX_AVG {
                background: #4cf58a1f;
                color: #4cf58a;
            }
        }

        &--info {
            margin-left: auto;
            font-size: 14px;
            color: #ccc;
        }
        &--row-2 {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
        }

        &--price {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 0px 10px;
            height: 24px;
            border-radius: 3px;

            >label {
                font-size: 13px;
                opacity: 0.7;
            }

            >span {
                font-size: 16px;
            }

            &.lower {
                @include text-color-negative;
                background: rgba(253, 129, 138, 0.15);
            }

            &.upper {
                @include text-color-positive;
                background: rgb(144 234 234 / 15%);
            }
        }

        &--buttons {
            margin-left: auto;
            display: flex;

            >button {
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                background: none;
                color: white;
                background: none;
                opacity: 0.4;
                transition: all 0.3s;

                &:hover {
                    opacity: 1.0;
                }
            }
        }

        &--delete-popup {
            @include background-color-2;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;
            padding: 10px;

            &--message {
                font-size: 14px;
                font-weight: bold;
            }

            >button {
                @include button-style-1;
                font-size: 14px;
                height: 28px;
                width: 100%;
                font-weight: bold;
                margin-top: 10px;
            }
        }
    }

    &--empty-data {
        position: relative;
        margin-top: 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #ccc;
    }
}