@import '../../styles/variables';

.config-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 15px;
    height: 100%;
    font-weight: bold;

    &--list {
        display: flex;
        flex-direction: column;
        width: 250px;
        min-width: 250px;

        &--header {
            padding: 5px 10px;
            background: #19364b;
            display: flex;
            align-items: center;
            font-size: 14px;
            height: 20px;
            color: #ccc;
            @include background-color-1;

            &--loading {
                margin-left: auto;
                width: 16px !important;
                height: 16px !important;
            }

            &--fetch-button {
                margin-left: auto;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                padding: 0px;
                text-align: center;
                @include button-style-3;
            }
        }

        &--main {
            margin-top: 10px;
        }

        &--item {
            padding: 10px 12px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                @include background-color-1;
            }

            &.selected {
                @include background-color-2;
            }

            &:not(:last-child) {
                border-bottom: 1px solid;
                @include border-color-1;
            }

            &--name {
                font-size: 16px;
            }

            &--footer {
                display: flex;
                align-items: center;
                font-size: 12px;
                margin-top: 10px;
                color: #bbb;

                >div {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    >label {
                        opacity: 0.6;
                        cursor: inherit;
                    }
                }
            }

            &--updated-timestamp {
                margin-left: auto;
            }
        }
    }

    &--editor {
        position: relative;
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        &--header {
            padding: 5px 10px;
            background: #19364b;
            display: flex;
            align-items: center;
            font-size: 14px;
            height: 20px;
            color: #ccc;
            @include background-color-1;
            gap: 10px;

            &--edited-mark {
                width: 8px;
                height: 8px;
                background: #eee;
                border-radius: 10px;
                box-shadow: 0px 0px 3px black;
            }

            &--buttons {
                margin-left: auto;
                display: flex;
                gap: 10px;
            }

            &--button {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: bold;
                gap: 5px;

                &.discard {
                    @include button-style-1;
                }

                &.save {
                    @include button-style-2;
                }

                &:disabled {
                    opacity: 0.4;
                }
            }
        }

        &--error-message {
            @include text-color-highlight-red;
            font-size: 14px;
            margin-top: 10px;
            background: #fd818a21;
            line-height: 24px;
            padding: 0px 10px;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;
        }

        &--main {
            position: relative;
            flex-grow: 1;
            margin-top: 10px;
        }
    }
}